import { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your EmailJS template parameters
    const templateParams = {
      from_name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    // Your EmailJS service ID, template ID, and user ID
    emailjs
      .send(
        "service_1cju4ts",
        "template_mqzny1b",
        templateParams,
        "zz9ZNsHju6ZSofDXH"
      )
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        templateParams.reset();
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      });
  };

  return (
    <div className="container mx-auto my-4 px-4 lg:px-20">
      <div className="w-full p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-[#2D24A1] sm:text-4xl">
            Contact Form
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            For inquiries or questions, please call us or send us a message
            using our online form below.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              placeholder="First Name"
              required
            />
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              placeholder="Last Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              placeholder="Email"
              required
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              placeholder="Phone"
              required
            />
          </div>
          <div className="my-4">
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
              required
            ></textarea>
          </div>
          <div className="my-2 w-1/2 lg:w-1/4">
            <button
              type="submit"
              className="uppercase text-sm font-bold tracking-wide bg-[#2D24A1] text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>

      <div class="w-full lg:-mt-96 lg:w-2/6 px-8 py-12 ml-auto bg-[#F6F6F6] rounded-2xl">
        <div class="flex flex-col">
          <p className="mt-4 text-lg leading-8 text-gray-900">
            Our commitment to service knows no time constraints, ensuring
            support whenever you require it.We operate 24/7 to cater to your
            needs.
          </p>

          <div class="flex my-4 w-2/3 lg:w-1/2">
            <div class="flex flex-col">
              <i class="fas fa-map-marker-alt pt-2 pr-2" />
            </div>
            <div class="flex flex-col">
              <dt className="mt-4 font-semibold text-[#2D24A1]">Address</dt>
              <dd className="mt-2 leading-7 text-gray-900">
                3890 Castle Hill drive, Brownsburg, IN, 46112
              </dd>
            </div>
          </div>

          <div class="flex my-4 w-2/3 lg:w-1/2">
            <div class="flex flex-col">
              <i class="fas fa-phone-alt pt-2 pr-2" />
            </div>
            <div class="flex flex-col">
              <dt className="mt-4 font-semibold text-[#2D24A1]">
                Phone Number
              </dt>
              <dd className="mt-2 leading-7 text-gray-900">+1(317) 998-2389</dd>
            </div>
          </div>
          <div class="flex my-4 w-2/3 lg:w-1/2">
            <div class="flex flex-col">
              <i class="fas fa-phone-alt pt-2 pr-2" />
            </div>
            <div class="flex flex-col">
              <dt className="mt-4 font-semibold text-[#2D24A1]">Email</dt>
              <dd className="mt-2 leading-7 text-gray-900">
                administrator@reliablehomecarellc.com
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
